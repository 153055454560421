<template>    
    <section class="about fun-facts section-padding" data-scroll-index="3" data-overlay-light="3" v-lazy:background-image="`${publicPath}images/pattern.jpg`">
        <div class="container">
            <div class="row">
                <div class="text-center col-lg-12">
                    <h5 class="fw-600 mb-20">We are expert of PSD to CMS solution providers</h5>
                    <p>psdtoany is an imaginative and modern Web Development Company and Web Design Company with a blend of diverse understanding of Internet Marketing strategies. A Custom Website is considered as the identity of a business and is believed to be a business’ strongest asset. Our team of professionals work with you to analyze your business needs to give you realistic advice and guidance to design, develop and effectively market your website over the internet.</p>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="col-md-6 col-lg-3">
                    <div class="item">
                        <h2><span class="icon"><i class="icofont-globe"></i></span> 
                        <span class="count">8</span></h2>
                        <h6>Year Experience</h6>
                    </div>  
                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="item">
                        <h2><span class="icon"><i class="icofont-image"></i></span> 
                        <span class="count">500</span></h2>
                        <h6>Delivered Projects</h6>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="item">
                        <h2><span class="icon"><i class="icofont-heart-eyes"></i></span> 
                        <span class="count">150</span></h2>
                        <h6>Happy Clients</h6>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="item">
                        <h2><span class="icon"><i class="icofont-users-alt-3"></i></span> 
                        <span class="count">10</span></h2>
                        <h6>Expert Team</h6>
                    </div>
                </div>                                
            </div>

        </div>
    </section>
</template>    
<script>
export default {
    data () {
        return {
            publicPath: process.env.BASE_URL
        }
    }    
}
</script>
